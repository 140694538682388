import React from "react"

export default function BoxAd({ ad }) {
    return (
        <>
            <div className="section retraction" style={{ display: `block` }}>
                <div className="superBox">
                    <div className="superBoxContainer">
                        { ad }
                    </div>
                    <div className="superBoxTitle">hirdetés <i className="fa fa-arrow-circle-up"></i></div>
                </div>
            </div>
        </>
    )
}