import React from "react"
import { Link } from "gatsby"
import brandLogo from "../../styles/images/brand-logo.png"

export default function Footbar() {

    return (
        <>
            <div className="foot_info">
                <div className="section retraction mobile-foot">
                    <div style={{ margin:0, padding:0 }}>
                        <ul className="nav navbar-nav" style={{ textAlign: `center`, display: `inline-block` }}>
                            <li><a href="/adatvedelmi-nyilatkozat">Adatvédelmi Nyilatkozat</a></li>
                            <li><a href="/impresszum">Impressszum</a></li>
                        </ul>
                    </div>
                    <div style={{ textAlign: `center` }}>
                        <div style={{ textAlign: `center`, display: `inline-block` }}>
                            <img 
                                src={ brandLogo } 
                                style={{ width: `130px`, display: `inline-block`, background: `#fff`, padding: `3px` }} 
                                width="132" height="54" alt="Minden Percben | Érdekes"
                            />
                        </div>
                        <p style={{ padding: `10px 0`, color: `#aaa`, fontSize: `14px` }}>Minden Percben | Mert minden perc érdekes © 2020</p>
                    </div>
                </div>
            </div>
        </>
    )
}