import React from "react"
import { Link } from "gatsby"
import brandLogo from "../../styles/images/brand-logo.png"

export default function Navbar({ refreshUrl }) {
    return (
        <>
            <div className="navigation retraction">
                <div className="box">
                    <div className="nav">
                        <h5>Menü</h5>
                        <ul>
                            <li><a href="/belfold"><span className="category_1_text"><i className="fas fa-bullseye"></i></span> Belföld</a></li>
                            <li><a href="/kulfold"><span className="category_2_text"><i className="fas fa-plane"></i></span> Külföld</a></li>
                            <li><a href="/gazdasag"><span className="category_4_text"><i className="fas fa-chart-line"></i></span> Gazdaság</a></li>
                            <li><a href="/techtud"><span className="category_5_text"><i className="fas fa-graduation-cap"></i></span> TechTud</a></li>
                            <li><a href="/kultura"><span className="category_7_text"><i className="fas fa-book"></i></span> Kultúra</a></li>
                            <li><a href="/sport"><span className="category_8_text"><i className="fas fa-futbol"></i></span> Sport</a></li>
                            <li><a href="/bulvar"><span className="category_9_text"><i className="fas fa-star"></i></span> Bulvár</a></li>
                            <li><a href="/eletmod"><span className="category_10_text"><i className="fas fa-star"></i></span> Életmód</a></li>
                            <li><a href="/idojaras"><span className="category_11_text"><i className="fas fa-cloud"></i></span> Időjárás</a></li>
                        </ul>
                    </div>
                </div>
            </div>
            <div className="header retraction">
                <div className="logo">
                    <a href="/" title="Minden Percben"><img src={ brandLogo } alt=""/></a>
                </div>
                <a href={ refreshUrl } className="refresh" title="Minden Percben"><i className="fas fa-history"></i></a>
                <span className="menu"><i className="fa fa-bars"></i></span>
            </div>
        </>
    )
}