import React from "react"
import { Helmet } from "react-helmet"
import Navbar from "../skeleton/navbar"
import Footbar from "../skeleton/footbar"
import BodyInjections from "../injections/bodyInjections"
import HeadInjections from "../injections/headInjections"
import BoxAd from "../crumbs/boxAd"

export default function LayoutSite({ refreshUrl, content }) {

  return (
    <>
      <Helmet>
        <HeadInjections />
      </Helmet>
      <div className="site">        
        <Navbar refreshUrl={refreshUrl} />
        <div className="section retraction" style={{ display: `block` }}>
          <div className="section retraction" style={{ display: `block`, marginTop: `15px` }}>
            <BoxAd
              ad={ <iframe src="https://mindenpercben.hu/r/1207834.html" width="640" height="320" frameborder="0" scrolling="no"></iframe> }
            />       
            { content }
          </div>          
        </div>  
        <Footbar />      
      </div>
      <BodyInjections />
    </>
  )
}