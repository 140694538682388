import React from "react"

/*import { withPrefix } from "gatsby"
<script src={withPrefix('toggle.js')} type="text/javascript" />*/

export default function HeadInjections() {
    return (
        <>
            <meta charset="utf-8" />
            <meta http-equiv="x-ua-compatible" content="ie=edge" />            
            <meta name="viewport" content="width=device-width, initial-scale=1.0" />            
        </>
    )
}